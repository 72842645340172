
import { mapActions } from 'pinia'
import { useUiStore } from "~/store/ui";

export default {
    props: {
        mode: {
            type: String,
            default: 'compact',
        },
        notification:{
            type: Object,
            default: {}
        }
    },
    methods: {
        ...mapActions(useUiStore, ['watchNotification'])
    }
}
